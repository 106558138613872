export const comments = {
  ja: {
    score: {
      up: '先月の生活習慣スコアより%d点上がりました。日々の心がけの成果ですね。自分ログで1つでも良い結果を残せたらスコアは上がります。日常のささいな習慣の積み重ねがご自身の将来の健康につながるので、良い習慣は上手く続けてください。今月からWalkチーム対抗戦のエントリーが始まります。豪華賞品、多数ご用意していますのでお楽しみに！2022年も＆wellを使いながら、健康的な1年を過ごしましょう。',
      down: '先月の生活習慣スコアより%d点下がりました。自分ログで1つでも良い結果を残せたらスコアは上がります。まずは誰にでもできる「小さな習慣」（自分ログの入力、腹八分目など）から始めていきましょう。日常のささいな習慣の積み重ねがご自身の将来の健康につながります。今月からWalkチーム対抗戦のエントリーが始まります。豪華賞品、多数ご用意していますのでお楽しみに！2022年も＆wellを使いながら、健康的な1年を過ごしましょう。',
      equal:
        '先月の生活習慣スコアと同じです。年末年始にかけて忙しい時期に、点数が下がることなく現状維持ができたことも1つの成果ではないでしょうか。項目別の評価も参考にしながら、どんなことを心がけていたのか振り返ってくださいね。今月からWalkチーム対抗戦のエントリーが始まります。豪華賞品、多数ご用意していますのでお楽しみに！2022年も＆wellを使いながら、健康的な1年を過ごしましょう。',
    },
    steps: {
      up: '先月より歩数スコアは%d点上がりました。寒くなると歩くことが億劫になる…。そんなあなたに朗報です。今年度最後のWalkチーム対抗戦が2月に開催！キリ番賞やユニークチーム名コンテストなど入賞チャンス多数あります。1月11日からエントリー開始しています。お見逃しなく。',
      down: '先月より歩数スコアは%d点下がりました。寒くなると歩くことが億劫になる…。そんなあなたに朗報です。今年度最後のWalkチーム対抗戦が2月に開催！キリ番賞やユニークチーム名コンテストなど入賞チャンス多数あります。1月11日からエントリー開始しています。お見逃しなく。',
      equal:
        '歩数スコアは先月と同じです。寒くなると歩くことが億劫になる…。そんなあなたに朗報です。今年度最後のWalkチーム対抗戦が2月に開催！キリ番賞やユニークチーム名コンテストなど入賞チャンス多数あります。1月11日からエントリー開始しています。お見逃しなく。',
    },
    exercise: {
      up: '先月より運動に関する習慣は%d点上がりました。人は呼吸をするだけでも筋肉を使います。いつもより活動量を少し上げるだけでGOOD！継続のコツは取り組む運動のハードルをぐんと下げて生活の中に取り入れることです。また「転ばぬ先のストレッチ」というWeeklyプログラムも今月配信しています。誰もがご存じの〇〇〇体操にすごい効果が…！',
      down: '先月より運動に関する習慣は%d点下がりました。人は呼吸をするだけでも筋肉を使います。いつもより活動量を少し上げるだけでGOOD！継続のコツは取り組む運動のハードルをぐんと下げて生活の中に取り入れることです。また「転ばぬ先のストレッチ」というWeeklyプログラムも今月配信しています。誰もがご存じの〇〇〇体操にすごい効果が…！',
      equal:
        '運動に関する習慣は先月と同じポイントです。人は呼吸をするだけでも筋肉を使います。いつもより活動量を少し上げるだけでGOOD！継続のコツは取り組む運動のハードルをぐんと下げて生活の中に取り入れることです。また「転ばぬ先のストレッチ」というWeeklyプログラムも今月配信しています。誰もがご存じの〇〇〇体操にすごい効果が…！',
    },
    meal: {
      up: '先月より食事に関する習慣は%d点上がりました。新年会など外食やごちそうを食べる機会が多くなりがちです。一時的に増えた体重は2，3日で元に戻すようにしましょう。2週間以上増えた状態が続いてしまうと、増えた分が脂肪になり体重を減らすのが難しくなるので気をつけましょう。',
      down: '先月より食事に関する習慣は%d点下がりました。新年会など外食やごちそうを食べる機会が多くなりがちです。一時的に増えた体重は2，3日で元に戻すようにしましょう。2週間以上増えた状態が続いてしまうと、増えた分が脂肪になり体重を減らすのが難しくなるので気をつけましょう。',
      equal:
        '食事に関する習慣は先月と同じポイントです。新年会など外食やごちそうを食べる機会が多くなりがちです。一時的に増えた体重は2，3日で元に戻すようにしましょう。2週間以上増えた状態が続いてしまうと、増えた分が脂肪になり体重を減らすのが難しくなるので気をつけましょう。',
    },
    drinking: {
      up: '先月より飲酒に関する習慣は%d点上がりました。1月12日から「最高のお酒の飲み方」に関する動画を配信しています。お酒が好きな方はもちろん、年末年始にお酒を飲む機会が多かった方などぜひご覧ください！美味しいお酒を長く楽しめるように、日々小さなことから心がけていきましょう。',
      down: '先月より飲酒に関する習慣は%d点下がりました。1月12日から「最高のお酒の飲み方」に関する動画を配信しています。お酒が好きな方はもちろん、年末年始にお酒を飲む機会が多かった方などぜひご覧ください！美味しいお酒を長く楽しめるように、日々小さなことから心がけていきましょう。',
      equal:
        '飲酒に関する習慣は先月と同じポイントです。1月12日から「最高のお酒の飲み方」に関する動画を配信しています。お酒が好きな方はもちろん、年末年始にお酒を飲む機会が多かった方などぜひご覧ください！美味しいお酒を長く楽しめるように、日々小さなことから心がけていきましょう。',
    },
    sleep: {
      up: '先月より睡眠に関する習慣は%d点上がりました。夜更かしや寝正月された方は、生活リズムを整えていきましょう。なかなか寝付けなかったり、寝る時間がバラバラになってしまう場合、起きる時間は同じになるように意識するのがおすすめです。最初は少し辛いかもしれませんが、だんだんと起床の準備を整えるホルモン「コルチゾール」の分泌時間がそろい、目覚めもスッキリになります！',
      down: '先月より睡眠に関する習慣は%d点下がりました。夜更かしや寝正月された方は、生活リズムを整えていきましょう。なかなか寝付けなかったり、寝る時間がバラバラになってしまう場合、起きる時間は同じになるように意識するのがおすすめです。最初は少し辛いかもしれませんが、だんだんと起床の準備を整えるホルモン「コルチゾール」の分泌時間がそろい、目覚めもスッキリになります！',
      equal:
        '睡眠に関する習慣は先月と同じポイントです。夜更かしや寝正月された方は、生活リズムを整えていきましょう。なかなか寝付けなかったり、寝る時間がバラバラになってしまう場合、起きる時間は同じになるように意識するのがおすすめです。最初は少し辛いかもしれませんが、だんだんと起床の準備を整えるホルモン「コルチゾール」の分泌時間がそろい、目覚めもスッキリになります！',
    },
    stress: {
      up: '長い連休をあけると、普段気が付かない疲れを感じることもあります。そんな時は、頭の中で3秒数えながら、ゆっくりと口から息を吐き出します。息を吐き出せたら、同じように3秒数えながら、今度は鼻から息を吸い込みます。シンプルなことですが、就業前、信号待ちの時、歯磨きの後など、ご自身のお好きなタイミングで深呼吸をして、心を落ち着かせてください。',
      down: '長い連休をあけると、普段気が付かない疲れを感じることもあります。そんな時は、頭の中で3秒数えながら、ゆっくりと口から息を吐き出します。息を吐き出せたら、同じように3秒数えながら、今度は鼻から息を吸い込みます。シンプルなことですが、就業前、信号待ちの時、歯磨きの後など、ご自身のお好きなタイミングで深呼吸をして、心を落ち着かせてください。',
      equal:
        '長い連休をあけると、普段気が付かない疲れを感じることもあります。そんな時は、頭の中で3秒数えながら、ゆっくりと口から息を吐き出します。息を吐き出せたら、同じように3秒数えながら、今度は鼻から息を吸い込みます。シンプルなことですが、就業前、信号待ちの時、歯磨きの後など、ご自身のお好きなタイミングで深呼吸をして、心を落ち着かせてください。',
    },
  },
  en: {
    score: {
      up: "Your score went up by %d points from last month's lifestyle score. This is the result of your daily efforts. If you can achieve even one good result in your log, your score will go up. The accumulation of small daily habits will lead to your future health, so please keep up the good habits.Entries for the Walk team competition will start this month. We've got a lot of great prizes up for grabs, so look forward to it! Let's continue to use &well in 2022 and have a healthy year ahead.",
      down: `Your score went up by %d points from last month's lifestyle score. If you can make even one good result in your self-log, your score will go up. Let's start with "small habits" that anyone can do, such as inputting your own log and eating moderately. Entries for the Walk team competition will start this month. We've got a lot of great prizes up for grabs, so look forward to it! Let's continue to use &well in 2022 and have a healthy year ahead.`,
      equal: `It's the same score as last month's lifestyle score. I think one of the results is that You were able to maintain your current status without any drop in score during the busy period of the year-end and New Year holidays. Entries for the Walk team competition will start this month. We've got a lot of great prizes up for grabs, so look forward to it! Let's continue to use &well in 2022 and have a healthy year ahead.`,
    },
    steps: {
      up: "Your score has increased by %d points since last month. When it gets cold, walking becomes a chore.... We have good news for you. The last Walk team competition of the year will be held in February! Entry is now open from January 11. Don't miss it!",
      down: "Your step count score has dropped by %d points since last month.When it gets cold, walking becomes a chore.... We have good news for you. The last Walk team competition of the year will be held in February! Entry is now open from January 11. Don't miss it!",
      equal:
        "Your score is the same as last month.When it gets cold, walking becomes a chore.... We have good news for you. The last Walk team competition of the year will be held in February! Entry is now open from January 11. Don't miss it!",
    },
    exercise: {
      up: 'Your score has increased by %d points since last month. We use our muscles just to breathe. The trick is to lower the hurdle of exercise and incorporate it into your life.We also have a weekly program called "Stretch Before You Fall" available this month. There is a great effect of exercise that everyone knows...!',
      down: 'Your score has dropped by %d points since last month. We use our muscles just to breathe. The trick is to lower the hurdle of exercise and incorporate it into your life.We also have a weekly program called "Stretch Before You Fall" available this month. There is a great effect of exercise that everyone knows...!',
      equal:
        'Your score is the same as last month. We use our muscles just to breathe. The trick is to lower the hurdle of exercise and incorporate it into your life.We also have a weekly program called "Stretch Before You Fall" available this month. There is a great effect of exercise that everyone knows...!',
    },
    meal: {
      up: "Your score has increased by %d points since last month. We tend to have many opportunities to eat out and feast, such as at New Year's parties. If you gain weight temporarily, try to put it back on in a few days; if it continues to increase for more than two weeks, the increase will become fat and it will be difficult to lose weight.",
      down: "Your score has dropped by %d points since last month.We tend to have many opportunities to eat out and feast, such as at New Year's parties. If you gain weight temporarily, try to put it back on in a few days; if it continues to increase for more than two weeks, the increase will become fat and it will be difficult to lose weight.",
      equal:
        "Your score is the same as last month.We tend to have many opportunities to eat out and feast, such as at New Year's parties. If you gain weight temporarily, try to put it back on in a few days; if it continues to increase for more than two weeks, the increase will become fat and it will be difficult to lose weight.",
    },
    drinking: {
      up: `Your score has increased by %d points since last month. Since January 12, we have been distributing a video on "How to drink the best alcohol". If you like to drink, or if you had a lot of chances to drink during the year-end and New Year holidays, please watch this video!Let's try to do small things every day so that we can enjoy good drinks for a long time.`,
      down: `Your score has dropped by %d points since last month. Since January 12, we have been distributing a video on "How to drink the best alcohol". If you like to drink, or if you had a lot of chances to drink during the year-end and New Year holidays, please watch this video!Let's try to do small things every day so that we can enjoy good drinks for a long time.`,
      equal: `Your score is the same as last month. Since January 12, we have been distributing a video on "How to drink the best alcohol". If you like to drink, or if you had a lot of chances to drink during the year-end and New Year holidays, please watch this video!Let's try to do small things every day so that we can enjoy good drinks for a long time.`,
    },
    sleep: {
      up: "Your score has increased by %d points since last month. If you are a night owl or a New Year's sleeper, try to adjust your daily rhythm. If you have a hard time falling asleep or go to bed at different times, it is recommended that you make a conscious effort to wake up at the same time. It may be a little difficult at first, but gradually the secretion of cortisol, the hormone that prepares you for waking up, will be aligned, and you will wake up feeling refreshed!",
      down: "Your score has dropped by %d points since last month.If you are a night owl or a New Year's sleeper, try to adjust your daily rhythm. If you have a hard time falling asleep or go to bed at different times, it is recommended that you make a conscious effort to wake up at the same time. It may be a little difficult at first, but gradually the secretion of cortisol, the hormone that prepares you for waking up, will be aligned, and you will wake up feeling refreshed!",
      equal:
        "Your score is the same as last month.If you are a night owl or a New Year's sleeper, try to adjust your daily rhythm. If you have a hard time falling asleep or go to bed at different times, it is recommended that you make a conscious effort to wake up at the same time. It may be a little difficult at first, but gradually the secretion of cortisol, the hormone that prepares you for waking up, will be aligned, and you will wake up feeling refreshed!",
    },
    stress: {
      up: "After a long holiday weekend, you may feel tired that you don't usually notice. When this happens, slowly exhale through your mouth while counting to three in your head. Once you have exhaled, breathe in through your nose while counting to three seconds. It's a simple thing to do, but take a deep breath before work, when waiting at a traffic light, after brushing your teeth, or any other time you like to calm your mind.",
      down: "After a long holiday weekend, you may feel tired that you don't usually notice. When this happens, slowly exhale through your mouth while counting to three in your head. Once you have exhaled, breathe in through your nose while counting to three seconds. It's a simple thing to do, but take a deep breath before work, when waiting at a traffic light, after brushing your teeth, or any other time you like to calm your mind.",
      equal:
        "After a long holiday weekend, you may feel tired that you don't usually notice. When this happens, slowly exhale through your mouth while counting to three in your head. Once you have exhaled, breathe in through your nose while counting to three seconds. It's a simple thing to do, but take a deep breath before work, when waiting at a traffic light, after brushing your teeth, or any other time you like to calm your mind.",
    },
  },
}
